<template>
	<div id="exam_detail" v-loading="loading" :style="{height: height + 'px'}">
		<div class="head u-f-item u-f-jsb">
            <div>{{info.name}}/补考查询</div>
        </div>
        <div class="content">
            <div class="title u-f-item u-f-jsb">
                <div>共{{total}}条数据</div>
                <div class="u-f-item">
                    <span class="span">选择条件</span>
                    
                    <el-select size="small" clearable v-model="classId" placeholder="班级" style="width: 150px;margin-left: 10px" @change="changeClass">
                    	<el-option v-for="item in classList" :key="item.id" :label="item.class_name" :value="item.id"></el-option>
                    </el-select>
                    <el-select size="small" v-model="discipline_id" placeholder="学科" style="width: 150px;margin-left: 10px;margin-right: 10px;" @change="changeSubject">
                    	<el-option v-for="item in subjectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                    <el-button type="primary" size="small" @click="examResultsExports">导出</el-button>
                </div>
            </div>
            <el-table
                :data="list"
                height="87%"
                ref="multipleTable"
                tooltip-effect="dark"
                style="width: 100%;border: 1px solid #EEEEEE;"
                key="2"
            >
                <el-table-column label="序号" type="index" width="70" align="center"></el-table-column>
                <!-- <el-table-column label="学籍号" prop="student_num" align="center"></el-table-column> -->
                <el-table-column label="姓名" prop="realname" align="center"></el-table-column>
                <el-table-column label="班级" prop="class_name" align="center"></el-table-column>
				<el-table-column label="班主任" prop="teacher_one_name" align="center"></el-table-column>
				<el-table-column label="学科" prop="discipline_name" align="center"></el-table-column>
				<el-table-column  label="分数" prop="score" align="center"></el-table-column>
            </el-table>
            <div class="u-f-item u-f-right" style="padding: 15px 0;">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="page"
                    :page-size="limit"
                    layout="total, prev, pager, next"
                    :total="total"
                ></el-pagination>
            </div>
        </div>
	</div>
</template>

<script>
export default {
	props: {
		
	},
	data() {
		return {
            height: 500,
			loading: false,
            id: '',
            info: {},
            page: 1,
            limit: 14,
            total: 0,
            list: [],
            titleList: [],
            VisibleTitle: '',
            addVisible: false,
            sousuo: '',
            ids: [],
            edit: false,
			classId:'',
			classList:[],
			discipline_id:'',
			subjectList:''
		};
	},
	created() {},
	mounted() {
        this.height = document.documentElement.clientHeight || document.body.clientHeight;
		this.id = this.$route.query.id;
        this.getInfo()
	},
	methods: {
		// 获取班级列表
		getClassList(){
			this.$api.setting.getClassList({
				filter: JSON.stringify({'grade_id': this.info.grade_id})
			}).then(res=>{
				this.classList = res.data.rows;
			})
		},
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getList()
        },
		// 获取学科列表
		getsubjectList(){
			this.$api.setting.subjectList({}).then(res=>{
				if(res.data.code==1){
					this.subjectList = res.data.data;
				}
			})
		},
		getInfo(){
            this.$api.student.detailexam({
                id: this.id,
            }).then(res=>{
                if (res.data.code == 1) {
                    this.info = res.data.data;
					this.getClassList()
					this.getsubjectList()
                    this.getList();
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
		changeClass(){
			this.page = 1;
			this.getList()
		},
		changeSubject(){
			this.page = 1;
			this.getList()
		},
        getList(){
			let data = {
				exam_id: this.id,
				page: this.page,
				limit: this.limit
			}
			if(this.classId){data.class_id = this.classId}
			if(this.discipline_id){data.discipline_id = this.discipline_id}
            this.$api.student.examResultsList(data).then(res=>{
                if (res.data.code == 1) {
                    let list = res.data.data;
                    this.total = res.data.total;
                    this.list = list;
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        // 导出
		examResultsExports(){
			let data = {
				exam_id: this.id
			}
			if(this.classId){data.class_id = this.classId}
			if(this.discipline_id){data.discipline_id = this.discipline_id}
			this.$api.student.examResultsExports(data).then(res=>{
			    if (res.data.code == 1) {
			        this.$message.success('导出成功')
			        location.href = res.data.data;
			    } else {
			        this.$message.error(res.data.msg);
			    }
			})
		}
	}
};
</script>

<style lang="scss" scope>
	#exam_detail{
       
        .head{
            background-color: #4998ff;
            padding: 15px 20px;
            div{
                color: #fff;
                font-size: 16px;
            }
        }
        .content {
            background-color: #ffffff;
            margin-top: 20px;
            padding: 0 30px;
            height: calc(100% - 100px);
            overflow-y: auto;
            .title {
                padding: 0 0 15px 0;
                .btn{
                    background-color: #f5f5f5;
                    padding: 5px;
                }
                .btn:hover{
                    color: #4998ff;
                    background: #e4f1ff;
                    cursor: pointer;
                }
            }
            .el-table th > .cell,
            .el-table .cell {
                overflow: hidden; /*超出部分隐藏*/
                white-space: nowrap; /*不换行*/
                text-overflow: ellipsis; /*超出部分文字以...显示*/
            }
            .el-table thead {
                background: #fafafa !important;
            }
            .el-table th {
                padding: 15px 5px !important;
                background: #fafafa !important;
            }
            .el-table::before {
                left: 0;
                bottom: 0;
                width: 100%;
                height: 0;
            }
            .el-table .btn{
                padding: 5px;
                text-align: center;
            }
        }
        .el-dialog__body {
            padding: 20px 20px 50px;
            .con-box {
                border-top: 1px solid #eee;
                margin-top: 15px;
                max-height: 400px;
                overflow-y: auto;
                >div{
                    margin-top: 10px;
                    background-color: #F6F8FB;
                    padding: 15px;
                    >div:first-child{
                        margin-right: 30%;
                    }
                }
                >div.none{
                    color: #d8dddf;
                }
                >div.can:hover{
                    color: #4998ff;
                    background: #e4f1ff;
                    cursor: pointer;
                }
                >div.selected{
                    background-color: #4998ff;
                    color: #fff;
                }
            }
            .page{
                margin-top: 10px;
                text-align: right;
            }
        }
        .el-dialog__footer {
            padding: 15px 20px 15px;
            box-shadow: 0px -5px 10px -5px #eeeeee;
        }
    }
	
</style>
